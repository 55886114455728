<template>
  <hb-modal v-model="dialog" v-if="dialog" :confirmation="true" size="medium" title="Write Off as Loss" show-help-link>
    <template v-slot:content>
      <div class="pa-6">
        Are you sure you want to write off the balance of {{lease.open_balance | formatMoney}} as a loss from space {{unit.number}} ?
      </div>
      <hb-form :label="'Reason for write-off'" required>
          <v-col cols="12">
            <div class="write-off-reason">
              <hb-textarea
                :disabled="isAuctionWriteOff"
                v-model="reason"            
                v-validate.disable="'required|max:1000'"
                id="reason"
                name="reason"
                data-vv-scope="reversal"
                data-vv-as="Reason for Write-off"
                :error="errors.has('reason')"
                :placeholder="'Enter Reason for write-off'"
              ></hb-textarea>
            </div>
          </v-col>
        </hb-form>
    </template>
    <template v-slot:actions>
      <hb-btn
        color="primary"
        @click="writeOff"
      >
        Write Off As a Loss
      </hb-btn>
    </template>
  </hb-modal>
</template>

<script>

  import api from '../../../assets/api';
  import { EventBus } from '../../../EventBus';
  import { notificationMixin } from  '../../../mixins/notificationMixin.js';
  export default {
    name: "WriteOff",
	mixins: [ notificationMixin ],
    data: () => ({
      reason: ''
    }),
    props: ['writeOffModal', 'lease', 'unit', 'contact', 'auction'],
    computed: {
      isAuctionWriteOff(){
        const isAuctionPaid = this.auction && this.auction?.payment_id && !this.auction?.deleted_at;
        this.reason = isAuctionPaid ? 'Auction Write-off' : '';
        return isAuctionPaid;
      },
      dialog: {
        get () {
          return this.writeOffModal
        },
        set (writeOffModal) {
          this.$emit('input', writeOffModal)
        }
      }
    },
    methods: {
      writeOff(){
        var invoice_ids = [];
        let lease = this.$props.lease;
        let contact = this.$props.contact;
        let unit = this.$props.unit;
        for(var j = 0; j < lease.OpenInvoices.length; j++) {        
          var obj = {};
          obj['invoice_id'] = lease.OpenInvoices[j].id;
          invoice_ids.push(obj);
        }

        var data = {
          amount: lease.balance,
          notes: this.reason,
          invoices: invoice_ids,
          contact_id: contact.id,
        }

        return api.post(this, api.LEASES + lease.id + '/invoices/write-off', data).then(r => {
          EventBus.$emit('refetch_credit_payments', {lease_id: lease.id});
          EventBus.$emit('reload_tenant_profile_leases');          
		  this.showMessageNotification({ type: 'success', description: `You have successfully written off ${this.$options.filters.formatMoney(lease.open_balance)} from Space ${unit.number}.` });
          this.$emit('input', false)
        }).catch(err => {
          this.showMessageNotification({ description: err });
        });
      }
    },
  }
</script>
