import moment from 'moment-timezone';

export default {
  methods: {
    getPropertyDateTime(property_id,properties, date = null) {
      if(!property_id && !properties?.length) return;
      const property = properties.find(p => property_id === p.id);
      const offSet = property?.utc_offset || "Etc/UTC";
      return moment(date || undefined).tz(offSet).format('YYYY-MM-DD HH:mm:ss');
    }
  }
}