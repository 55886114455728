import { render, staticRenderFns } from "./CollectPayment.vue?vue&type=template&id=5d7e6e6b&scoped=true&"
import script from "./CollectPayment.vue?vue&type=script&lang=js&"
export * from "./CollectPayment.vue?vue&type=script&lang=js&"
import style0 from "./CollectPayment.vue?vue&type=style&index=0&id=5d7e6e6b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d7e6e6b",
  null
  
)

export default component.exports