<template>
    <div>

        <hb-form
            label="Military Status"   
            multiple
            editable
            :active.sync="editing" 
            @editStart="setCache"
            @cancel="loadCache('status')"
            :start-open="!selected.id"
            :hide-actions="!selected.id"
            @save="update('save')"
            description="Are you Active Duty?"
        >

            <template v-slot:display>
                {{ selected.active ? 'Yes': 'No' }}
            </template> 
            <template v-slot:edit>
                <hb-radio-group 
                    row 
                    v-model="selected.active"
                    :error="!!errors.collect('military_active').length > 0"
                    hide-details
                    @change="update"
                >
                    <hb-radio :value="0" label="No"></hb-radio>  
                    <hb-radio :value="1" label="Yes"></hb-radio>  
                </hb-radio-group>
            </template>
        </hb-form>
        <hb-form
            label="Commanding Officer Information"
            required  
            full
            v-show="selected.active"
            editable
            :active.sync="editing" 
            @editStart="setCache"
            @cancel="loadCache"
            @save="update('save')"
            :start-open="!selected.id"
            :hide-actions="!selected.id"
        >
        
            <template v-slot:display>
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0">{{selected.CommandingOfficer.first}} {{selected.CommandingOfficer.last}}</v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0">{{selected.CommandingOfficer.Phone.type}} {{selected.CommandingOfficer.Phone | displayPhone | formatPhone}}  <span style="color: #637381;" v-if="selected.CommandingOfficer.Phone.sms">Can SMS</span></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0">{{selected.rank}}</v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0 hb-text-overflow-wrap">{{selected.CommandingOfficer.email}}</v-col>
                </v-row>
            </template> 
            <template v-slot:edit>
                <v-row no-gutters>
                    <v-col cols="12" lg="6" class="ma-0 pa-0">
                        <HbTextField
                            v-validate="selected.active ? 'required|max:45' : 'max:45'"
                            data-vv-as="Commanding Officer First Name"
                            v-model="selected.CommandingOfficer.first"
                            id="military_first"
                            name="military_first"
                            :error="!!errors.collect('military_first').length > 0"
                            placeholder="First Name"
                            @input="update"
                        />
                    </v-col>

                    <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                        <HbTextField
                            v-validate="selected.active ? 'required|max:45' : 'max:45'"
                            data-vv-as="Commanding Officer Last Name"
                            v-model="selected.CommandingOfficer.last"
                            id="military_last"
                            name="military_last"
                            :error="!!errors.collect('military_last').length > 0"
                            placeholder="Last Name"
                            @input="update"
                        />
                    </v-col>

                </v-row>

                <div v-if="onboarding === true">

                    <v-row no-gutters>
                        <v-col class="ma-0 pa-0">
                            <hb-select
                                :items="phone_types"
                                v-validate="selected.active ? 'required' : ''"
                                data-vv-as="Commanding Officer Phone Type"
                                v-model.lazy="selected.CommandingOfficer.Phone.type"
                                placeholder="Phone Type"
                                @input="update"
                                id="military_phone_type"
                                name="military_phone_type"
                                :error="!!errors.collect('military_phone_type').length > 0"
                                :clearable="false"
                            ></hb-select>
                        </v-col>

                        <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                            <hb-autocomplete
                                :items="country_codes"
                                v-validate="selected.active ? 'required' : ''"
                                data-vv-as="Commanding Officer Country Code"
                                v-model.lazy="selected.CommandingOfficer.Phone.country_code"
                                placeholder="Phone Country Code"
                                @input="update"
                                id="military_phone_cc"
                                name="military_phone_cc"
                                :error="!!errors.collect('military_phone_cc').length > 0"
                                :clearable="false"
                            >
                                <template v-slot:prepend-inner>
                                    +
                                </template>
                                <template v-slot:item="data">
                                    +{{data.item}}
                                </template>
                            </hb-autocomplete>

                        </v-col>
        
                        <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                            <hb-text-field
                                v-validate="selected.active ? 'required|max:45' : 'max:45'"
                                data-vv-as="Commanding Officer Phone Number"
                                v-model="selected.CommandingOfficer.Phone.phone"
                                id="military_phone"
                                v-mask="'(###) ###-####'"
                                name="military_phone"
                                @input="update"
                                :error="!!errors.collect('military_phone').length > 0"
                                placeholder="Phone Number"
                            ></hb-text-field>
                        </v-col>

                        <v-col cols="12" lg="2" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                            <hb-checkbox
                                id="military_phone_sms"
                                name="military_phone_sms"
                                data-name="military_phone_sms"
                                v-model="selected.CommandingOfficer.Phone.sms"
                                @input="update"
                                label="SMS"
                            >
                            </hb-checkbox>
                        </v-col>
                    </v-row>

                </div>

                <div v-else>

                    <v-row no-gutters>
                        <v-col cols="12" lg="3" class="ma-0 pa-0">
                            <hb-select
                                :items="phone_types"
                                v-validate="selected.active ? 'required' : ''"
                                data-vv-as="Commanding Officer Phone Type"
                                v-model.lazy="selected.CommandingOfficer.Phone.type"
                                placeholder="Phone Type"
                                @input="update"
                                id="military_phone_type"
                                name="military_phone_type"
                                :error="!!errors.collect('military_phone_type').length > 0"
                                :clearable="false"
                            ></hb-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" lg="3" class="ma-0 pa-0">
                            <hb-autocomplete
                                :items="country_codes"
                                v-validate="selected.active ? 'required' : ''"
                                data-vv-as="Commanding Officer Country Code"
                                v-model.lazy="selected.CommandingOfficer.Phone.country_code"
                                placeholder="Phone Country Code"
                                @input="update"
                                id="military_phone_cc"
                                name="military_phone_cc"
                                :error="!!errors.collect('military_phone_cc').length > 0"
                                :clearable="false"
                            >
                                <template v-slot:prepend-inner>
                                    +
                                </template>
                                <template v-slot:item="data">
                                    +{{data.item}}
                                </template>
                            </hb-autocomplete>

                        </v-col>
        
                        <v-col cols="12" lg="5" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                            <hb-text-field
                                v-validate="selected.active ? 'required|max:45' : 'max:45'"
                                data-vv-as="Commanding Officer Phone Number"
                                v-model="selected.CommandingOfficer.Phone.phone"
                                id="military_phone"
                                v-mask="'(###) ###-####'"
                                name="military_phone"
                                @input="update"
                                :error="!!errors.collect('military_phone').length > 0"
                                placeholder="Phone Number"
                            ></hb-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                            <hb-checkbox
                                id="military_phone_sms"
                                name="military_phone_sms"
                                data-name="military_phone_sms"
                                v-model="selected.CommandingOfficer.Phone.sms"
                                @input="update"
                                label="SMS"
                            >
                            </hb-checkbox>
                        </v-col>
                    </v-row>

                </div>

                <v-row no-gutters>
                    <v-col cols="12" lg="6" class="ma-0 pa-0">
                        <hb-select
                            @click:clear="selected.rank = ''"
                            @input="update"
                            data-vv-as="Commanding Officer Rank"
                            :clearable="true"
                            :items="rank"
                            id="military_rank"
                            name="military_rank"
                            :error="!!errors.collect('military_rank').length > 0"
                            v-model="selected.rank"
                            placeholder="Rank"
                        ></hb-select>
                    </v-col>
                    <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                        <hb-text-field
                            @input="update"
                            data-vv-as="Commanding Officer Email"
                            v-validate="{ max:100, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
                            v-model="selected.CommandingOfficer.email"
                            id="commanding_officer_email"
                            name="commanding_officer_email"
                            :error="!!errors.collect('commanding_officer_email').length > 0"
                            placeholder="Email"
                        ></hb-text-field>
                    </v-col>
                </v-row>
            </template>
        </hb-form>
        <hb-form
            label="Service Member Information"
            required
            full
            v-show="selected.active"
            editable
            :active.sync="editing" 
            @editStart="setCache"
            @cancel="loadCache"
            @save="update('save')"
            :start-open="!selected.id"
            :hide-actions="!selected.id"
            
        >
            <template v-slot:display>
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0">{{selected.identification_number}}</v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0 hb-text-overflow-wrap">{{selected.service_member_email}}</v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0">{{selected.date_of_birth | formatDate}}</v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0">{{selected.service_expiration | formatDate}}</v-col>
                </v-row>
            </template>

            <template v-slot:edit>

                <v-row no-gutters>
                    <v-col class="ma-0 pa-0">
                        <hb-text-field
                            @input="update"
                            v-validate="selected.active ? 'required|max:45' : 'max:45'"
                            data-vv-as="Active Duty ID Number"
                            v-model="selected.identification_number"
                            id="identification_number"
                            name="identification_number"
                            :error="!!errors.collect('identification_number').length > 0"
                            placeholder="Military Serial Number"
                        ></hb-text-field>
                    </v-col>
                </v-row>
                
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0">
                        <hb-text-field
                            @input="update"
                            v-validate="{ max:100, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
                            data-vv-as="Active Duty Email"
                            v-model="selected.service_member_email"
                            id="military_email"
                            name="military_email"
                            :error="!!errors.collect('military_email').length > 0"
                            placeholder="Military Email"
                        ></hb-text-field>
                    </v-col>
                </v-row>
                

                <v-row no-gutters>
                    <v-col cols="12" lg="6" class="ma-0 pa-0">
                        <hb-text-field
                            v-model="selected.date_of_birth"
                            data-vv-as="Service Member Date of Birth"
                            id="service_member_dob"
                            @input="update"
                            name="service_member_dob"
                            v-mask="'##/##/####'"
                            :error="!!errors.collect('service_member_dob').length > 0"
                            placeholder="Service Member DOB (MM/DD/YYYY)"
                        ></hb-text-field>
                    </v-col>
                    <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                        <hb-text-field
                            v-model="selected.service_expiration"
                            v-validate="'max:45'"
                            data-vv-as="Expiration Term of Service"
                            id="service_expiration"
                            @input="update"
                            name="service_expiration"
                            v-mask="'##/##/####'"
                            :error="!!errors.collect('service_expiration').length > 0"
                            placeholder="Expiration Term of Service"
                        ></hb-text-field>
                    </v-col>
                </v-row>
            </template>
        </hb-form>
        <hb-form
            label="Military Unit"
            required 
            full
            v-show="selected.active"
            editable
            :active.sync="editing" 
            @editStart="setCache"
            @cancel="loadCache"
            @save="update('save')"
            :start-open="!selected.id"
            :hide-actions="!selected.id"
        >
            <template v-slot:display>
                <v-row no-gutters v-if="selected.branch"><v-col class="ma-0 pa-0">{{selected.branch}}</v-col></v-row>
                <v-row no-gutters v-if="selected.unit_name"><v-col class="ma-0 pa-0">{{selected.unit_name}}</v-col></v-row>
                 <v-row no-gutters>
                    <v-col class="ma-0 pa-0">{{selected.Phone.type}} {{selected.Phone | displayPhone | formatPhone}}  <span style="color: #637381;" v-if="selected.Phone.sms">Can SMS</span></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0">{{selected.Address.address}} {{selected.Address.address2}}<br />
                        {{ selected.Address | formatAddress }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:edit>
                <v-row no-gutters>
                    
                    <v-col cols="12" lg="6" class="ma-0 pa-0">
                        <hb-text-field
                            v-model="selected.branch"
                            v-validate="selected.active ? 'required|max:45' : 'max:45'"
                            data-vv-as="Military Branch"
                            @input="update"
                            id="military_branch"
                            name="military_branch"
                            :error="!!errors.collect('military_branch').length > 0"
                            placeholder="Military Branch"
                        ></hb-text-field>
                        
                    </v-col>
                    <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                        <hb-text-field
                            v-model="selected.unit_name"
                            v-validate="'max:45'"
                            data-vv-as="Military Unit Name"
                            id="unit_name"
                            name="unit_name"
                            @input="update"
                            :error="!!errors.collect('unit_name').length > 0"
                            placeholder="Military Unit Name"
                        ></hb-text-field>
                    </v-col>
                </v-row>

                <div v-if="onboarding === true">

                    <v-row no-gutters>

                        <v-col cols="12" lg="3" class="ma-0 pa-0">
                            <hb-select
                                :items="phone_types"
                                data-vv-as="Military Unit Phone Type"
                                v-model.lazy="selected.Phone.type"
                                placeholder="Phone Type"
                                @input="update"
                                id="unit_phone_type"
                                name="unit_phone_type"
                                :error="!!errors.collect('unit_phone_type').length > 0"
                                :clearable="false"
                            ></hb-select>
                        </v-col>

                        <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                            <hb-autocomplete
                                :items="country_codes"
                                data-vv-as="Military Unit Phone County Code"
                                v-model.lazy="selected.Phone.country_code"
                                placeholder="Country Code"
                                id="unit_phone_cc"
                                name="unit_phone_cc"
                                @input="update"
                                :error="!!errors.collect('unit_phone_cc').length > 0"
                                @change="checkValidPhone(contactPhoneCode, contactPhoneNumber)"
                                @focusout="checkValidPhone(contactPhoneCode, contactPhoneNumber)"
                                :clearable="false"
                            >
                                <template v-slot:prepend-inner>
                                    +
                                </template>
                                <template v-slot:item="data">
                                    +{{data.item}}
                                </template>
                            </hb-autocomplete>

                        </v-col>
        
                        <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                            <hb-text-field
                                data-vv-as="Military Unit Phone Number"
                                v-model="selected.Phone.phone"
                                v-validate="'max:45'"
                                id="unit_phone_number"
                                @input="update"
                                v-mask="'(###) ###-####'"
                                name="unit_phone_number"
                                :error="!!errors.collect('unit_phone_number').length > 0"
                                placeholder="Phone Number"
                            ></hb-text-field>
                        </v-col>
                        <v-col cols="12" lg="2" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                            <hb-checkbox
                                id="unit_phone_sms"
                                name="unit_phone_sms"
                                data-name="unit_phone_sms"
                                :error="!!errors.collect('unit_phone_sms').length > 0"
                                v-model="selected.Phone.sms"
                                label="SMS"
                            >
                            </hb-checkbox>
                        </v-col>
                    </v-row>

                </div>

                <div v-else>

                    <v-row no-gutters>
                        <v-col class="ma-0 pa-0">
                            <hb-select
                                :items="phone_types"
                                data-vv-as="Military Unit Phone Type"
                                v-model.lazy="selected.Phone.type"
                                placeholder="Phone Type"
                                @input="update"
                                id="unit_phone_type"
                                name="unit_phone_type"
                                :error="!!errors.collect('unit_phone_type').length > 0"
                                :clearable="false"
                            ></hb-select>
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="12" lg="3" class="ma-0 pa-0">
                            <hb-autocomplete
                                :items="country_codes"
                                data-vv-as="Military Unit Phone County Code"
                                v-model.lazy="selected.Phone.country_code"
                                placeholder="Country Code"
                                id="unit_phone_cc"
                                name="unit_phone_cc"
                                @input="update"
                                :error="!!errors.collect('unit_phone_cc').length > 0"
                                @change="checkValidPhone(contactPhoneCode, contactPhoneNumber)"
                                @focusout="checkValidPhone(contactPhoneCode, contactPhoneNumber)"
                                :clearable="false"
                            >
                                <template v-slot:prepend-inner>
                                    +
                                </template>
                                <template v-slot:item="data">
                                    +{{data.item}}
                                </template>
                            </hb-autocomplete>

                        </v-col>
        
                        <v-col cols="12" lg="5" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                            <hb-text-field
                                data-vv-as="Military Unit Phone Number"
                                v-model="selected.Phone.phone"
                                v-validate="'max:45'"
                                id="unit_phone_number"
                                @input="update"
                                v-mask="'(###) ###-####'"
                                name="unit_phone_number"
                                :error="!!errors.collect('unit_phone_number').length > 0"
                                placeholder="Phone Number"
                            ></hb-text-field>
                        </v-col>
                        <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                            <hb-checkbox
                                id="unit_phone_sms"
                                name="unit_phone_sms"
                                data-name="unit_phone_sms"
                                :error="!!errors.collect('unit_phone_sms').length > 0"
                                v-model="selected.Phone.sms"
                                label="SMS"
                            >
                            </hb-checkbox>
                        </v-col>
                    </v-row>

                </div>

                <v-row no-gutters>
                    
                    <v-col class="ma-0 pa-0">
                        <hb-text-field
                            v-model="selected.Address.address"
                            v-validate="'max:45'"
                            data-vv-as="Military Unit Street"
                            id="military_address"
                            name="military_address"
                            @input="update"
                            :error="!!errors.collect('military_address').length > 0"
                            placeholder="Street"
                        ></hb-text-field>
                        
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0">
                        <HbAutocomplete
                          :items="countriesListMixin"
                          v-model="selected.Address.country"
                          label="Country"
                          single-line
                          id="military_country"
                          data-vv-as="Driver's License Country"
                          name="military_country"
                          :error="!!errors.collect('military_country').length > 0"
                          @change="setCountry" 
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="ma-0 pa-0">
                        <hb-text-field
                            v-model="selected.Address.address2"
                            v-validate="'max:45'"
                            data-vv-as="Military Unit Suite / Apt"
                            id="military_address2"
                            name="military_address2"
                            :error="!!errors.collect('military_address2').length > 0"
                            placeholder="Suite / Apt"
                        ></hb-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    
                    <v-col cols="12" lg="3" class="ma-0 pa-0">
                        <hb-text-field
                            v-model="selected.Address.zip"
                            v-validate="'max:45'"
                            data-vv-as="Military Unit ZIP Code"
                            id="military_zip"
                            name="military_zip"
                            @input="update"
                            :error="!!errors.collect('military_zip').length > 0"
                            placeholder="ZIP Code"
                        ></hb-text-field>
                        
                    </v-col>
                    <v-col cols="12" lg="5" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                        <HbAutocomplete
                            :items="militaryStateListMixin"
                            v-model.lazy="selected.Address.state"
                            data-vv-as="Military Unit State"
                            placeholder="State"
                            item-text="name"
                            item-value="value"
                            id="military_state"
                            name="military_state"
                            :error="!!errors.collect('military_state').length > 0"
                            @change="setState" 
                        />
                    </v-col>
                    <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                        <HbAutocomplete
                            :items="militaryCityListMixin"
                            v-model.lazy="selected.Address.city"
                            v-validate="'max:45'"
                            data-vv-as="Military Unit City"
                            id="military_city"
                            name="military_city"
                            @change="setCity"
                            :error="!!errors.collect('military_city').length > 0"
                            placeholder="City"
                            @keydown.enter="setCityList($event.target.value, true)"
                        />
                    </v-col>
                </v-row>
            </template>
        </hb-form>
    </div>
</template>


<script type="text/babel">
import ADDRESS from '@/constants/address.js';
import PHONE from '@/constants/phone.js'; 
import ACTIVE_MILITARY from '@/constants/active_military.js'; 
import moment from 'moment';
import { parsePhoneNumber } from 'libphonenumber-js';
import {  mapActions, mapGetters } from "vuex";
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import { countryStateCityMixin } from '../../mixins/countryStateCityMixin';

export default{
        name: "ContactMilitaryInformation",
        mixins: [ countryStateCityMixin, notificationMixin ],
        data(){
            return {
                cache: {}, 
                editing: '',
                phone_types: PHONE.TYPES, 
                country_codes: PHONE.COUNTRY_CODES, 
                states: ADDRESS.STATES, 
                selected:{
                    id: '',
                    commanding_officer_contact_id: '',
                    address_id: '',
                    Phone: {
                        type: 'Cell',
                        country_code: this.company_phone_code,
                        phone: '',
                        sms: 0,
                    }, 
                    Address: {
                        address: '',
                        address2: '',
                        country: '',
                        city: '',
                        state: '',
                        zip: ''
                    },
                    CommandingOfficer: {
                        first: '',
                        last: '',
                        email: '',
                        Phone: {
                            type: 'Cell',
                            country_code: this.company_phone_code,
                            phone: '',
                            sms: 0,
                            primary: 1
                        }, 
                    },
                    active: 0,
                    branch: '',
                    service_member_email: '',
                    service_expiration: '', 
                    identification_number: '',
                    rank:'',
                    date_of_birth: '',
                    unit_name:'',
                }, 
                
                branch: ACTIVE_MILITARY.BRANCHES,
                rank: ACTIVE_MILITARY.RANKS
            }
        },
        inject: ['$validator'],
        props: ['value', 'tenantProfile', 'onboarding'],
        created(){
           this.setCountriesListEvent();
           this.setData();
           if(!this.selected.id){
               this.setCache();
                this.selected.Phone.country_code = this.company_phone_code;
                this.selected.CommandingOfficer.Phone.country_code = this.company_phone_code;
           }
        },
        filters:{
            displayPhone(phone){
                return phone.country_code + phone.phone 
            }
        },
        mounted(){
            
        },
        computed: {
            ...mapGetters({
              company_phone_code: 'authenticationStore/getCompanyPhoneCode',
           }),
        },
        methods: {
            setCountry(){
                this.selected.Address.state = ''
                this.selected.Address.city = ''
                this.update()
                this.setMilitaryStatesListEvent(this.selected.Address.country)
                this.setMilitaryCountryCitiesListEvent(this.selected.Address.country)
            },
            setState(){
                this.selected.Address.city = ''
                this.update()
                this.setMilitaryCitiesListEvent(this.selected.Address.country, this.selected.Address.state)
            },
            setCity(){
                this.update()
            },
            setCityList(value, toValidate = false){
                if(!value) return  
                const isSpecialCharacter = this.validateCityName(value)
                const militaryCityValidation = this.errors.items.indexOf('military_city')

                if(toValidate){
                    this.errors.items.splice(militaryCityValidation);
                    if(isSpecialCharacter){
                        return this.errors.add({
                                    field: 'military_city',
                                    msg: 'Please add a valid city name.'
                                }) 
                    }
                }
                
                const isCityExists = this.militaryCityListMixin.some((city) => city.toLowerCase() === value.toLowerCase())
                if(isCityExists) return

                this.militaryCityListMixin.push(value)
                const country = this.countriesListMixinRaw.find(item => item.name === this.selected.Address.country);
                this.addMissingCity({
                    country_code: country.isoCode,
                    state_code: this.selected.Address.state,
                    city: value
                })
            },
            setData(){

                if(this.value.id) {
                    this.selected = JSON.parse(JSON.stringify(this.value));
                }

                this.selected.Address.country = this.setCountryNameEvent(this.value.Address?.country)
                this.selected.Address.state = this.value.Address?.state || ''
                this.setMilitaryStatesListEvent(this.selected.Address.country)
                if(!this.selected.Address.state){
                    this.setMilitaryCountryCitiesListEvent(this.selected.Address.country)
                }else{
                    this.setMilitaryCitiesListEvent(this.selected.Address.country, this.selected.Address.state || this.value.Address.state)
                }
                this.setCityList(this.selected.Address.city)

                try {
                    var parsePhone = parsePhoneNumber('+' + this.selected.Phone.phone);
                    this.selected.Phone.phone = parsePhone.nationalNumber
                    this.selected.Phone.country_code = parsePhone.countryCallingCode
                }  catch(err){
                    console.log(err);
                }

                try {
                    var parseCommandingOfficerPhone = parsePhoneNumber('+' + this.selected.CommandingOfficer.Phone.phone);
                    this.selected.CommandingOfficer.Phone.phone = parseCommandingOfficerPhone.nationalNumber
                    this.selected.CommandingOfficer.Phone.country_code = parseCommandingOfficerPhone.countryCallingCode
                }  catch(err){
                    console.log(err);
                }

                
                if(this.selected.date_of_birth && this.selected.date_of_birth.length){
                    this.selected.date_of_birth = moment(this.selected.date_of_birth).format('MM/DD/YYYY')
                }
                if(this.selected.service_expiration && this.selected.service_expiration.length){
                    this.selected.service_expiration = moment(this.selected.service_expiration).format('MM/DD/YYYY')
                }
                

            },
            async update(type = 'input'){
                if(type !== 'save'){
                    type = 'input'; 
                }

                let data = this.updateData();

                this.$emit(type, data);
            
                if(type === 'save'){    
                    let status = await this.$validator.validateAll();
                    if(!status) return;
                    this.setCache();
                    this.editing = 'saved';
                }
            
            },
            updateData(){
                
                let data = JSON.parse(JSON.stringify(this.selected));

                if(data.Phone.phone){
                    data.Phone.phone = data.Phone.country_code + data.Phone.phone
                }

                if(data.CommandingOfficer.Phone.phone){
                    data.CommandingOfficer.Phone.phone = data.CommandingOfficer.Phone.country_code + data.CommandingOfficer.Phone.phone
                }
                
                if(data.date_of_birth){
                    data.date_of_birth = moment(data.date_of_birth).format('YYYY-MM-DD')
                }
                if(data.service_expiration){
                    data.service_expiration = moment(data.service_expiration).format('YYYY-MM-DD')
                }

                if(data.CommandingOfficer.Phone.phone){
                    data.CommandingOfficer.Phone.phone = data.CommandingOfficer.Phone.phone.replace(/[^0-9]/g, ''); 
                }

                if(data.Phone.phone){
                    data.Phone.phone = data.Phone.phone.replace(/[^0-9]/g, ''); 
                }

                return data;
            
            },
            setCache(){
                
                // hack to prevent saving before the other field loses focus
                window.setTimeout(() => {
                    this.cache = Object.assign({}, JSON.parse(JSON.stringify(this.selected)))
                }, 500); 
            },
            loadCache(){
                this.selected = Object.assign({}, JSON.parse(JSON.stringify(this.cache)))
            }
        },
        watch:{
            value:{
                handler: function(val, old) {
    
                    if(!this.cache.id && val.id){
                        this.selected.id = val.id; 
                        this.updateData();
                    }
                },
                deep:true
            }
        }
}


</script>
