
<!-- Modified by BCT - Hb-72 Space Profile -->
<!-- Modified by BCT - Hb-72 Space Profile -->
<!-- Modified by BCT - Hb-72 Space Profile -->
<!-- BCT - Spaces will now have amenities/features configured from property settings -->
<!-- Modified by BCT - Hb-72 Space Profile -->
<!-- Modified by BCT - Hb-72 Space Profile -->
<!-- Modified by BCT - Hb-72 Space Profile -->

<template>
<div>
    <div v-if="featuresLoading">
      <div class="text-center px-10 py-10">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <hb-empty-state
        v-else-if="!amenities || Object.keys(amenities).length === 0"
        message="There are no features and amenities. First, add features and amenities from the settings page."
        class="ma-3"
    >
    </hb-empty-state>
    <div v-else class="slide-out-section custom-border pa-0 ma-0">
        <v-col class="pa-0 border-bottom" :style="{ backgroundColor: editAmenity ? '#E0F5F5': '#FFF' }">
            <div class="my-3 mx-4 align-right" v-if="!editAmenity">
                <span class="alignleft">Click edit to update the amenities for this space.</span>
                <span><hb-link @click="setActiveEdit()">Edit</hb-link></span>
            </div>
            <div class="py-2 mr-3 mt-0 align-right justify-end" v-if="editAmenity">
                <hb-link class="mr-2" @click="cancelEdit()">Cancel</hb-link>
                <hb-btn color="primary" class="mr-2 pt-2" :disabled="isLoading($options.name)" @click="save">Save</hb-btn>
                <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </div>
        </v-col>
        <amenity-selector
            v-if="editAmenity"
            :amenityList="amenities"
            :show-help-text="false"
            @update="updateAmenityList"
            @updateAmenitySelection="updateAmenitySelection"
        ></amenity-selector>

        <div v-else-if="Object.entries(getAmenities).length > 0">
            <div :key="ind" v-for="(cat, i, ind) in getAmenities">
                <v-row class="hb-table-row pa-0 ma-0 border-bottom">
                    <v-col class="hb-table-label py-4 pl-6" cols="4">
                        {{i}}
                    </v-col>
                    <v-col cols="8" class="px-3">
                        <v-row class="ma-0">
                            <v-col cols="12" class="pa-1 pt-1 pb-2 pl-4" v-for="(am, index) in cat" :class="{'pt-1' : (index == 0 && ind == 0) || (index == 1 && ind == 0)}">
                                
                                <v-row class="ma-0">
                                    <v-col class="col-12 pa-0 font-14px">
                                        <div>{{am.feature}}: 
                                        <span class="light-text"> 
                                            {{data[am.property_amenity_mapping_id]}}
                                        </span></div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="border-bottom text-grey pa-4" v-else>
            There are no features and amenities assigned to this space.
        </div>

        <v-col class="pa-0 justify-end" :style="{ backgroundColor: editAmenity ? '#E0F5F5': '#FFF' }">
            <div class="my-3 mx-4 align-right justify-end" v-if="!editAmenity">
                <hb-link @click="setActiveEdit()">Edit</hb-link>
            </div>
            <div class="py-2 mr-3 align-right justify-end" v-if="editAmenity">
            <hb-link class="mr-2" @click="cancelEdit()">Cancel</hb-link>
                <hb-btn color="primary" class="mr-2" :disabled="isLoading($options.name)" @click="save">Save</hb-btn>
                <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </div>
        </v-col>

    </div>

    <!-- // Restricted Permission warning popup -->
    <hb-modal
      v-model="showNoPermissionDialog"
      size="medium"
      title="Unable Add/Edit Features and Amenities"
      :footerOff="true"
      confirmation
      show-help-link
      @close="showNoPermissionDialog = false"
    >
      <template v-slot:content>
        <div class="px-6 py-4">
          <div class="pb-2">The signed in account does not have permission to add/edit features and amenities.</div>
          <div>To add/edit features and amenities, contact your administrator to enable permission.</div>
        </div>
      </template>
    </hb-modal>
    <!-- Restricted Permission warning popup // -->
</div>

</template>


<script type="text/babel">
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import { mapGetters, mapActions } from "vuex";
    import AmenitySelector from "../assets/spaceManagementComponents/AmenitySelector.vue";
    import { EventBus } from '../../EventBus.js';
    import { notificationMixin } from  '@/mixins/notificationMixin.js';
    import { cloneDeep } from "lodash";

    export default {
        name: "UnitAmenities",
        data() {
            return {
                data: {},
                amenities: {},
                unitAmenities:[],
                editAmenity: false,
                showNoPermissionDialog: false,
                featuresLoading: true
            }
        },
        components:{
            Loader,
            AmenitySelector
        },
        computed: {
            ...mapGetters({
            addedFeaturesList: "featureAndAmenitiesStore/addedFeaturesList",
            hasPermission: 'authenticationStore/rolePermission',
            }),
            getAmenities () {
                const amenities = {};
                Object.entries(this.amenities).forEach((category) => {
                    const valid = category[1].filter((amenity) => (amenity.property_default_value && amenity.property_default_value?.toLowerCase() !== "no") || amenity.parent_amenity_id)
                    if (valid.length > 0) {
                        amenities[category[0]] = valid;
                    }
                })
                return amenities;
            }
        },
        filters:{
            decode(list){
                try{
                    return list;
                }catch(err){
                    console.log(err);
                    return [];
                }

            }
        },
        props: ['unit_id', 'property_id', 'unit_number','unit_type_id'],
        mixins: [notificationMixin],
        created(){

            this.fetchAmenities();
        },
        mounted(){

        },
        methods:{
            ...mapActions({
                fetchFeatures: "featureAndAmenitiesStore/fetchFeatures"
            }),
            setVal(){

            },
            async fetchData(){
                await api.get(this, api.UNITS + this.unit_id + '/amenities').then(r => {
                    this.unitAmenities = r.unit.Amenities;
                    for (var amenityType in r.unit.Amenities) {
                        r.unit.Amenities[amenityType].forEach(a => {
                            if (this.amenities[a.category_name])   {
                                this.amenities[a.category_name].forEach(am =>  {
                                    if (a.amenity_property_id === am.property_amenity_mapping_id ) {
                                        am.selected = true;
                                        am.property_default_value = a.value;
                                    }
                                });
                            }
                            this.$set(this.data,a.amenity_property_id, a.value);
                        })
                    }
                    this.featuresLoading = false;
                });
            },

            async fetchAmenities(){
                let featureCategoryMap = {};
                await this.fetchFeatures({
                    property_id: this.property_id,
                    unit_type_id:this.unit_type_id
                });
                let addedFeaturesListDuplicate = cloneDeep(this.addedFeaturesList)
                addedFeaturesListDuplicate.forEach(feature => {
                    let category = feature.property_category_name
                        ? feature.property_category_name
                        : feature.master_category_name;
                    this.$set(this.data,feature.property_amenity_mapping_id, null);
                    feature.selected = false;
                    if (feature.property_default_value?.toLowerCase() !== "no") {
                        feature.property_default_value = "No";
                    }
                    if (featureCategoryMap[category]) {
                        featureCategoryMap[category].push(feature);
                    } else {
                        featureCategoryMap[category] = [feature];
                    }
                 });
                this.amenities = featureCategoryMap;
                this.fetchData();
            },

            updateAmenityList(data) {
                Object.entries(this.amenities).map(amenities => {
                    if (amenities[0] === data.category) {
                    const newAmenities = amenities[1].map(amenity => ({
                        ...amenity,
                        selected: true,
                        property_default_value:
                        amenity.property_amenity_mapping_id === data.id
                            ? data.value
                            : amenity.property_default_value
                    }));
                    this.amenities[data.category] = newAmenities;
                    }
                });
            },

            save(){
                const updatedAmenities = {};
                Object.entries(this.amenities).map(amenities => {
                     amenities[1].map(amenity => {
                         if(!amenity.property_default_value) {
                             this.data[amenity.property_amenity_mapping_id] = null;
                             return;
                         }
                        this.data[amenity.property_amenity_mapping_id] = amenity.property_default_value;
                        updatedAmenities[amenity.property_amenity_mapping_id] = {
                            amenity_id: amenity.amenity_id,
                            value: amenity.property_default_value
                        }
                     })
                 });
                var send = { amenities: updatedAmenities, property_id: this.property_id }
	            this.validate(this).then(status => {
		            if (!status) return;

		            api.post(this, api.UNITS + this.unit_id + '/amenities?unit_type_id=' + this.unit_type_id, send).then(r => {
                        this.showMessageNotification({type: 'success', description: `Features and Amenities have been updated for Space ${this.unit_number}`});
                        this.cancelEdit();
                        EventBus.$emit('unit_amenities_edited_single', this.amenities, this.unit_id);
                        }).catch(err => {
                            this.showMessageNotification({type: 'error', description: err});
                    });
                });
            },
            setActiveEdit(){
                if (this.hasPermission('add_edit_features_amenities_existing_spaces')) {
                    this.editAmenity = true;
                } else {
                    this.showNoPermissionDialog = true;
                }
            },
            cancelEdit(){
                this.editAmenity = false;
            }

        },
        watch:{
            unit_id(){
                this.amenities = [];
                this.unitAmenities = [];
                this.fetchData();
                this.fetchAmenities();
            },
            "errors.items"(val) {             
				this.showMessageNotification({ id: this.$options.name, type: "error", list: val });
            }
        }

    }


</script>


<style scoped>
    .list-section-header{
        padding: 15px;
    }
    .lined{
        border-bottom: 1px solid #dce8ef;
    }
    .border-bottom{
        border-bottom: 1px solid #ccc !important;
    }
    .font-14px{
        font-size: 14px;
        color: #101318;
        font-weight: 400;
    }
    .themed-color{
        background-color: rgb(224, 245, 245);
    }
    .light-text{
        color: rgba(0, 0, 0, 0.6);
    }
    .alignleft {
        float: left;
    }
    .add-border {
    border-style: dashed;
    border-color: lightgrey;
    }
    .text-grey {
    color: grey;
    }

</style>