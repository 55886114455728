<template>
  <!-- Form to input rent change -->
  <hb-form
    class="hb-text-night manual-rent-form"
    full
    :label="isEdit ? 'Next Rent Change' : 'Rent'"
  >
    <div v-if="isEdit" class="mb-2 hb-text-light hb-font-body">
      <span class="hb-text-light font-weight-medium">Note: </span>
      Selecting a different month will skip the rent change for previously
      selected month.
    </div>
    <div v-if="currentRent" class="mb-2 hb-text-light hb-font-body">
      Current Rent: {{ currentRent | formatMoney }}
    </div>
    <div class="hb-font-body-medium mb-1">New Rent:</div>
    <hb-radio-group
      v-model="rentChange.newRentRadio"
      class="mb-0"
      :error="errorDict.newRentError"
    >
      <hb-radio value="fixed" @click="setFixedValue()">
        <template v-slot:label>
          <div class="d-flex align-center">
            Fixed Amount
            <hb-text-field
              v-model="rentChange.fixedRentAmount"
              :disabled="rentChange.newRentRadio !== 'fixed'"
              maxlength="10"
              placeholder="$"
              class="pl-3 pt-3"
              :error="errorDict.fixedAmountError"
              box
              style="max-width: 78px"
              medium
              condensed
            >
            </hb-text-field>
          </div>
        </template>
      </hb-radio>
      <hb-radio value="increaseRent" class="mt-n3">
        <template v-slot:label>
          <v-row class="d-flex align-center">
            <v-col cols="5" class="pr-2" style="max-width: fit-content">
              Increase Rent By
            </v-col>
            <v-col cols="5" class="pb-1 pl-0">
              <hb-Select
                v-model="rentChange.rentByMethod"
                placeholder="Select Method"
                :clearable="false"
                dense
                full
                :error="errorDict.increaseMethodError"
                :items="['$ amount', '% of current rent']"
                :disabled="rentChange.newRentRadio !== 'increaseRent'"
              ></hb-Select>
            </v-col>
            <v-col cols="2" class="pa-0 pt-4">
              <hb-text-field
                v-model="rentChange.increaseByAmount"
                :error="errorDict.increaseValueError"
                :disabled="!rentChange.rentByMethod"
                maxlength="10"
                placeholder="0"
                style="max-width: 68px"
                medium
                box
                condensed
              >
              </hb-text-field>
            </v-col>
          </v-row>
        </template>
      </hb-radio>
    </hb-radio-group>
    <div class="d-flex align-center mb-0 mt-n2" style="width: 100%">
      <div class="px-0 py-0 mb-1 mr-2 d-flex hb-font-body-medium">
        Deployment Month:
      </div>
      <v-col cols="6" class="pa-0 pt-1">
        <v-menu
          v-model="menu"
          :nudge-right="40"
          transition="scale-transition"
          :close-on-content-click="false"
          y-offset
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <hb-text-field
              class="pt-0 mt-0"
              v-model="formattedDate"
              placeholder="Choose Month"
              prepend-inner-icon="mdi-calendar-blank"
              @click:prepend-inner="menu = true"
              append-icon="mdi-menu-down"
              v-bind="attrs"
              v-on="on"
              @click:clear="() => (rentChange.deploymentDate = '')"
              readonly
              dense
              :error="errorDict.monthError"
            ></hb-text-field>
          </template>
          <v-date-picker
            scrollable
            no-title
            header-color="#00848E"
            color="#00848E"
            width="220"
            v-model="rentChange.deploymentDate"
            :min="currentMonth"
            :type="'month'"
            :show-current="false"
            :picker-date.sync="pickerDate"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </div>
    <v-row v-if="hasInternalRentEngine && getPropertyRentSettings.automation_enabled_by_admin" class="d-flex align-center mx-0 mt-2">
      <HbCheckbox
        v-model="rentChange.affectAutomaticRent"
        label="Do not affect automated rent change timeline"
        tooltip-header="Automated Rent Change"
        tooltip-body="By checking this checkbox, the manually created rent change will not impact the timeline of the automated rent plan. 
        The automated rent plan stage will proceed as originally scheduled, unaffected by the manually created rent adjustment."
      />
    </v-row>
    <div class="rent-data mt-2" v-if="rentData">
      <div v-if="rentData.lastChange && rentData.scheduledDate" class="mb-0">
        <span class="hb-text-light">Last change: </span>+{{
          rentData.lastChange
        }}%,
        {{ rentData.scheduledDate | formatDateTimeCustom("MMM, YYYY") }}
      </div>
      <div v-if="isValidValue(rentData.sellRate)" class="mb-0">
        <span class="hb-text-light">Sell Rate: </span
        >{{ rentData.sellRate | formatMoney }}
      </div>
      <div v-if="isValidValue(rentData.setRate)" class="mb-0">
        <span class="hb-text-light">Set Rate: </span
        >{{ rentData.setRate | formatMoney }}
      </div>
      <div v-if="isValidValue(rentData.variance)" class="mb-0">
        <span class="hb-text-light">Variance (Current Rent/Sell): </span>
          {{ formatMoney(rentData.variance) }}
        <span v-if="isValidValue(rentData.variancePrct)">/ {{ rentData.variancePrct.toFixed(2) }}%</span> 
      </div>
    </div>
    <div class="pt-0 mb-2">
      <div class="pb-0 mb-0 hb-font-caption hb-text-night-light">Notes:</div>
      <HbTextarea
        width="85%"
        v-model="rentChange.rentChangeReason"
        placeholder="Enter Reason"
        maxlength="250"
      />
    </div>
    <ConfirmationPopup v-once ref="confirmationPopup" />
  </hb-form>
</template>

<script>
import moment from "moment";
import ConfirmationPopup from "@/components/revenue_management/utils/ConfirmationPopup.vue";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import api from "@/assets/api.js";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "../../../EventBus.js";
import { formatMoneyByCurrency, isEmpty } from "@/utils/common";
import { cloneDeep, isEqual } from "lodash";

export default {
  name: "RentChangeForm",

  mixins: [notificationMixin],

  components: {
    ConfirmationPopup,
  },
  props: {
    currentRent: {
      type: Number,
      default: 0,
    },
    rentData: {
      type: Object,
      default: () => ({}),
    },
    rentChangeData: {
      type: Object,
      default: () => ({
        increaseByAmount: "",
        fixedRentAmount: "",
        rentChangeReason: "",
        newRentRadio: "",
        rentByMethod: "",
        deploymentDate: "",
        affectAutomaticRent: false,
      }),
    },
    ids: {
      type: Array,
      default: () => [],
    },
    bulk: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    tenantProperty: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      rentChange: null,
      menu: false,
      showConfirm: true,
      initialRentData: null,
      errorDict: {
        newRentError: false,
        fixedAmountError: false,
        increaseMethodError: false,
        increaseValueError: false,
        monthError: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      bulkEditProperty: "propertiesStore/bulkEditProperty",
      selectedProperty: "revManStore/getSelectedProperty",
      getPropertyRentSettings: "revManStore/getPropertyRentSettings",
      hasInternalRentEngine: "revManStore/hasInternalRentEngine"
    }),

    formattedDate: {
      get() {
        const date = this.rentChange.deploymentDate ?? "";
        return date ? moment(date, "YYYY-MM").format("MMMM, YYYY") : "";
      },
      set(value) {
        this.rentChange.deploymentDate = value ?? "";
      },
    },

    changeType() {
      if (this.rentChange.newRentRadio == "fixed") return "fixed";
      return this.rentChange?.rentByMethod?.startsWith("$")
        ? "dollar_amount"
        : "rent_percent";
    },

    currentMonth() {
      return moment().add(1, "month").format("YYYY-MM");
    },

    changeValue() {
      return this.rentChange?.newRentRadio == "fixed"
        ? this.rentChange?.fixedRentAmount
        : this.rentChange?.increaseByAmount;
    },

    validatePercentage() {
      return this.changeType === "dollar_amount"
        ? "between:1,100000|decimal:2"
        : "between:1,100|decimal:0";
    },

    isRentChangeFormValid() {
      const { newRentRadio, deploymentDate, rentByMethod } = this.rentChange;
      return (
        !!newRentRadio &&
        !!deploymentDate &&
        !!(
          this.changeValue &&
          (newRentRadio === "increaseRent" ? !!rentByMethod : true)
        )
      );
    },

    pickerDate: {
      get() {
        return !this.menu ? this.currentMonth: null
      },
      set(val) {
        return val
      }
    }
  },

  methods: {
    ...mapActions({}),

    formatMoney(cost) {
      return !isNaN(parseFloat(cost))
        ? formatMoneyByCurrency(parseFloat(cost))
        : "";
    },

    isValidValue(value) {
      return !isEmpty(value);
    },

    clearManualRentModal() {
      this.$emit("closeRentChangeModal", this.ids);
      this.rentChange = {
        increaseByAmount: "",
        fixedRentAmount: "",
        rentChangeReason: "",
        newRentRadio: "",
        rentByMethod: "",
        deploymentDate: "",
        affectAutomaticRent: false,
      };
    },
    async validateFields() {
      let errors = [];
      const validAmountRegex = /^(?:[1-9]\d{0,3}|10000)(?:\.\d{1,2})?$/;
      const validPercentRegex = /^[1-9]?[0-9]$/;
      if (this.rentChange.newRentRadio) {
        this.errorDict.newRentError = false;
      } else {
        errors.push("New Rent Method is required.");
        this.errorDict.newRentError = true;
      }

      if (this.rentChange.newRentRadio === "fixed") {
        if (!this.rentChange.fixedRentAmount) {
          errors.push("Fixed Amount field is required.");
          this.errorDict.fixedAmountError = true;
        } else {
          const amount = Number(this.rentChange.fixedRentAmount);
          if (
            !amount ||
            amount < 1 ||
            amount > 10000 ||
            !validAmountRegex.test(this.rentChange.fixedRentAmount)
          ) {
            errors.push(
              "Fixed Amount field should be a number between 1 and 10000 with up to 2 decimal points."
            );
            this.errorDict.fixedAmountError = true;
          } else this.errorDict.fixedAmountError = false;
        }
      }

      if (this.rentChange.newRentRadio === "increaseRent") {
        if (!this.rentChange.rentByMethod) {
          errors.push("Increase by Method field is required.");
          this.errorDict.increaseMethodError = true;
        } else {
          if (this.rentChange.rentByMethod === "$ amount") {
            if (!this.rentChange.increaseByAmount) {
              errors.push("Increase by Amount field is required.");
              this.errorDict.increaseValueError = true;
            } else {
              const amount = Number(this.rentChange.increaseByAmount);
              if (
                !amount ||
                amount < 1 ||
                amount > 10000 ||
                !validAmountRegex.test(this.rentChange.increaseByAmount)
              ) {
                errors.push(
                  "Increase by Amount field should be a number between 1 and 10000 with up to 2 decimal points."
                );
                this.errorDict.increaseValueError = true;
              } else this.errorDict.increaseValueError = false;
            }
          } else {
            if (!this.rentChange.increaseByAmount) {
              errors.push("Increase by Percentage field is required.");
              this.errorDict.increaseValueError = true;
            } else {
              const amount = Number(this.rentChange.increaseByAmount);
              if (
                !amount ||
                amount < 1 ||
                amount > 100 ||
                !validPercentRegex.test(this.rentChange.increaseByAmount)
              ) {
                errors.push(
                  "Increase by Percentage field should be a number between 1 and 100 with 0 decimal points."
                );
                this.errorDict.increaseValueError = true;
              } else this.errorDict.increaseValueError = false;
            }
          }
        }
      }

      if (!this.rentChange.deploymentDate) {
        errors.push("Deployment Month field is required.");
        this.errorDict.monthError = true;
      } else this.errorDict.monthError = false;

      if (this.rentChange.rentByMethod) {
        this.errorDict.increaseMethodError = false;
      }

      if (errors.length) {
        this.showMessageNotification({
          description: "There are errors in your form, correct them before continuing.",
          list: errors,
        });
      }

      return !errors.length;
    },

    async applyManualRentChange(enableDryrun = true) {
      const success = await this.validateFields();
      if (!success) return;
      this.$emit("loading", true);
      const IdType = this.isEdit ? "rent_change_id" : "lease_id";
      let body = this.ids.map((id) => {
        return {
          [IdType]: id,
          change_value: this.changeValue,
          change_type: this.changeType,
          target_date: moment(this.rentChange.deploymentDate).format(
            "YYYY-MM-DD"
          ),
          affect_timeline: !this.rentChange.affectAutomaticRent,
          note: this.rentChange.rentChangeReason,
          dryrun: enableDryrun,
        };
      });
      let url =
        api.getPropertyRentManagementUrl(
          this.bulk
            ? this.bulkEditProperty
            : this.tenantProperty
            ? this.tenantProperty
            : this.selectedProperty
        ) + "rent-change";
      try {
        let apiMethod = this.isEdit ? "put" : "post";
        let response = await api[apiMethod](this, url, body, "", false);
        if (enableDryrun) {
          this.showManualConfirmationPopup();
        } else {
          this.showMessageNotification({
            type: "success",
            description: `You have updated the rent change for ${
              this.bulk ? "selected tenants" : "this tenant"
            }.
                                The rent change will be added to the queue for the month of
                                ${this.formattedDate.split(",")[0]}`,
            timeout: 3000,
          });
          this.clearManualRentModal();
          if (this.bulk) this.$emit("cancel");
          else if (this.tenantProperty) EventBus.$emit("contact_updated");
          EventBus.$emit("tenantRentManagementEvent", {
            event: "refreshTable",
          });
          EventBus.$emit("reviewRentChangeEvents", { event: "refreshTable" });
          EventBus.$emit("rentChangeQueueEvents", "refreshTable");
        }
        return response;
      } catch (error) {
        if (enableDryrun && error?.body?.actual_cause?.error) this.errConfirmationPopup(error);
        else
          this.showMessageNotification({
            type: "error",
            description: error?.body?.msg.split(":")[0] ?? "Rent updation failed",
            timeout: 3000, 
          });
      } finally {
        this.$emit("loading", false);
      }
    },
    /**
     * Manual rent change confirmation pop up
     */
    async showManualConfirmationPopup(type = "active") {
      this.confirmationType = type;
      let confirmed = await this.$refs.confirmationPopup
        .show({
          title: `Confirm Rent Change `,
          message: `You have scheduled a rent change for ${
            this.formattedDate.split(",")[0]
          }.<br><br>Would you like to proceed with this ${
            !this.isEdit ? "manual" : ""
          } rent change?`,
          buttonType: type == "delete" ? "destructive" : "primary",
          resolver: `Save`,
        })
        .catch(() => false);
      if (confirmed) this.applyManualRentChange(false);
    },

    async errConfirmationPopup(err, type = "active") {
      this.confirmationType = type;
      let msg = "";
      let requiredError = 0;
      err?.body?.actual_cause?.error?.forEach((error) => {
        let liElements = error.lease_info.map(
          (info) =>
            `<li class="pt-1">#${info.unit_number} - (${info.tenant_name})</li>`
        );
        msg += `${error.reason}:
                <ul class="pl-6 mb-4" style="list-style: disc">
                  ${liElements.join("")}
                </ul>`;
        if (error.required) {
          requiredError += 1;
        }
      });
      this.showConfirm = requiredError ? false : true;
      msg += `${this.showConfirm ? "Are you sure you want to continue?" : ""}
    `;
      let confirmed = await this.$refs.confirmationPopup
        .show({
          title: `${
            !this.showConfirm ? "Rent Change Failed" : "Confirm Rent Change"
          }`,
          message: msg,
          buttonType: type == "delete" ? "destructive" : "primary",
          resolver: `${this.showConfirm ? "Confirm" : ""}`,
          hideFooter: !this.showConfirm,
        })
        .catch(() => false);
      if (confirmed) this.applyManualRentChange(false);
    },
    setFixedValue() {
      if (this.rentData?.newRent && !this.rentChange.fixedRentAmount)
        this.rentChange.fixedRentAmount = cloneDeep(this.rentData.newRent);
    },
  },

  watch: {
    rentChangeData: {
      handler(value) {
        this.rentChange = Object.assign({}, value);
      },
      deep: true,
      immediate: true,
    },

    rentChange: {
      deep: true,
      immediate: true,
      handler(value) {
        let validateValue = !isEqual(this.initialRentData, this.rentChange);
        this.$emit("valueChanged", validateValue);
      },
    },

    rentData: {
      deep: true,
      immediate: true,
      handler() {
        Object.keys(this.errorDict).forEach((key) => {
          this.errorDict[key] = false;
        });
        this.errors.items = [];
        if (this.isEdit && !this.bulk) {
          if (this.rentData?.newRent)
            this.rentChange.fixedRentAmount = cloneDeep(this.rentData.newRent);
          this.rentChange.newRentRadio = "fixed";
          if (this.rentData?.deploymentDate)
            this.rentChange.deploymentDate = cloneDeep(
              moment(this.rentData.deploymentDate).format("YYYY-MM")
            );
          this.rentChange.affectAutomaticRent = !this.rentData?.affectAutomaticRent;
          this.initialRentData = cloneDeep(this.rentChange);
        }
      },
    },

    isRentChangeFormValid(value) {
      this.$emit("validForm", value);
    },
    "rentChange.newRentRadio": {
      handler(value) {
        if (value === "fixed") {
          this.rentChange.increaseByAmount = "";
          this.rentChange.rentByMethod = "";
        } else {
          this.rentChange.fixedRentAmount = "";
        }
      },
    },
  },
};
</script>

<style lang="scss">
.manual-rent-form {
  .new-rent-inputs
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding: 0 6px;
    min-height: 100%;
    height: 32px;
    width: 106px;
  }
}
</style>
<style lang="scss" scoped>
.rent-data {
  line-height: 20px;
}
</style>
