<template>
    <div :style="{ width: mainView.id === 'national_account' ? '150px' : '125px' }">
        <v-select :items="sortedSpaceOptions" item-text="Unit.number" item-value="id" return-object background-color="white"
            outlined dense hide-details :menu-props="{ bottom: true, offsetY: true, contentClass: 'hb-elevation-large' }"
            v-model="mainView" height="28" id="mainView" class="vuetify-input-fieldset-fix">
            <template v-slot:selection="data">
                <div class="hb-input-overflow d-flex align-center">
                    <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1"
                        v-if="data.item.Unit.type === 'tenant'" mdi-icon="mdi-account"></hb-icon>
                    <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1"
                        v-if="data.item.Unit.type === 'national_account'" mdi-icon="mdi-web"></hb-icon>
                    <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1"
                        v-else-if="data.item.Unit.type === 'storage'" mdi-icon="mdi-facility-custom-3"></hb-icon>
                    <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1"
                        v-else-if="data.item.Unit.type === 'parking'" mdi-icon="mdi-rv-truck"></hb-icon>
                    <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1"
                        v-else-if="data.item.Unit.type === 'company'" mdi-icon="mdi-domain"></hb-icon>
                    <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1"
                        v-else-if="data.item.Unit.type === 'residential'" mdi-icon="mdi-domain"></hb-icon>
                    <span :class="leaseSelectionClass(data.item)">{{ data.item.Unit.number }}</span>

                </div>
            </template>
            <template v-slot:item="data">

                <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1" v-if="data.item.Unit.type === 'tenant'"
                    mdi-icon="mdi-account"></hb-icon>
                <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1"
                    v-if="data.item.Unit.type === 'national_account'" mdi-icon="mdi-web"></hb-icon>
                <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1"
                    v-else-if="data.item.Unit.type === 'storage'" mdi-icon="mdi-facility-custom-3"></hb-icon>
                <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1"
                    v-else-if="data.item.Unit.type === 'parking'" mdi-icon="mdi-rv-truck"></hb-icon>
                <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1"
                    v-else-if="data.item.Unit.type === 'company'" mdi-icon="mdi-domain"></hb-icon>
                <hb-icon :color="leaseSelectionColor(data.item)" class="pr-1"
                    v-else-if="data.item.Unit.type === 'residential'" mdi-icon="mdi-domain"></hb-icon>
                <span :class="leaseSelectionClass(data.item)">{{ data.item.Unit.number }}</span>

            </template>
        </v-select>
    </div>
</template>

<script>

export default {
    name: 'SpaceSelectionDropdown',
    props: {
        mainViewOptions: Array,
        setSelection: {},  // No type specified
        resetFilters: {},  // No type specified
        from: {
            type: String,
            // default: 'national_account'
        },
        propertyView: {
            type: String,
        },
        isNational: {
            type: Boolean
        }
    },
    data() {
        return {
            mainViewOptionsCopy :[],
            sortedSpaceOptions: [],
            mainView : this.isNational ? {id: 'national_account', Unit: {number: 'Account', type: 'national_account'}} : {id: null, Unit: {number: 'Tenant', type: 'tenant'}},
            activeLeaseCount: 0
        };
    },
    mounted() {
        this.resetMainView();
        if (this.from == 'send-email' || this.from == 'send-sms') {
        if (this.propertyView === 'all-property') {
            this.mainViewOptionsCopy = this.mainViewOptions.slice();
            const index = this.mainViewOptionsCopy.findIndex(item => item.id === null);
            if (index !== -1) {
                this.mainViewOptionsCopy.splice(index, 1);
            }
            this.mainView = {
                    id: "national_account",
                    Unit: {
                        number: "Account",
                        type: "national_account"
                    }
                }
                this.sortSpaceByLease(JSON.parse(JSON.stringify(this.mainViewOptionsCopy)))
        } 
        else {
                this.mainView = {
                    id: null,
                    Unit: {
                        number: 'Tenant',
                        type: 'tenant',
                    }
                }
                this.sortSpaceByLease(JSON.parse(JSON.stringify(this.mainViewOptions)))
            }
            
    } else {
        // Handling for national account case
        this.sortSpaceByLease(JSON.parse(JSON.stringify(this.mainViewOptions)));
        if (this.from === 'national_account' && this.propertyView === 'all-property') {
            this.mainView = {
                id: "national_account",
                Unit: {
                    number: "Account",
                    type: "national_account"
                }
            };
        }
    }
    },
    watch: {
        mainView() {
            this.$emit('spaceSelectionChanged', this.mainView)
        },
        mainViewOptions(newValue) {
            this.sortSpaceByLease(JSON.parse(JSON.stringify(newValue)))
        },
        isNational(newValue) {
        // Reset the mainView based on the isNational value
        this.resetMainView();
    },
        // just for tenant documents INC-7950
        resetFilters() {
        this.resetMainView(); 
    }
    },

    methods: {
        isLeaseEnded(lease) {
            if (lease.end_date?.length) {
                return true;
            }

            return false;
        },
        leaseSelectionColor(lease) {
            return this.isLeaseEnded(lease) ? '#637381' : '#101318';
        },
        leaseSelectionClass(lease) {
            return this.isLeaseEnded(lease) ? 'hb-text-night-light' : 'hb-text-night';
        },
        sortSpaceByLease(newValue) {
            this.sortedSpaceOptions = newValue
            this.sortedSpaceOptions = this.sortedSpaceOptions.sort((a, b) => {
                if (!a?.id) {
                    return 1
                } else {
                    if (this.isLeaseEnded(a) === this.isLeaseEnded(b)) {
                        return 0;
                    } else if (this.isLeaseEnded(a)) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

            })
            this.countActiveLeases()
            if (this.setSelection && this.activeLeaseCount > 1) {
                if (this.sortedSpaceOptions[0].id === 'national_account') {
                    if (this.sortedSpaceOptions.length > 2) {
                        // OL-4107 - If we are having space, then select first space by default
                        this.mainView = this.sortedSpaceOptions[2];
                    } else {
                         // OL-4107 - If we are not having space, then select Tenant by default
                        this.mainView = this.sortedSpaceOptions[1];
                    }
                }
                else {
                    this.mainView = this.sortedSpaceOptions[1];
                }
            }
            // For national account, the option will be always one (Account).So setselection flag will be false
            else if ( this.sortedSpaceOptions.length === 1 && this.sortedSpaceOptions[0].id === 'national_account') {
                this.mainView = this.sortedSpaceOptions[0];
            }

            this.$emit('setDefaultSpaceValue', this.mainView);
        },
        countActiveLeases() {
            this.activeLeaseCount = 0
            this.sortedSpaceOptions.forEach((lease) => {
                if (!lease.end_date?.length) {
                    this.activeLeaseCount += 1
                }
            });
        },
        resetMainView() {
        if (this.isNational) {
            this.mainView = {
                id: 'national_account',
                Unit: { number: 'Account', type: 'national_account' }
            };
        } else {
            this.mainView = {
                id: null,
                Unit: { number: 'Tenant', type: 'tenant' }
            };
        }
    },

    },
};
</script>

<style lang="scss" scoped></style>