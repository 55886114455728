<template>
    <v-row class="ma-0 pb-3" v-if="useVSelect">
        <VSelect
            v-model="selectedHours"
            :items="propertyAccessHours"
            item-text="name"
            v-bind="$attrs"
            return-object
            placeholder=""
            :loading="fetchAccessHoursInProgress"
            :disabled="fetchAccessHoursInProgress || disabled"
            :clearable="false"
            label="Access Hours"
            dense
            flat
        />
    </v-row>
    <div v-else>
        <HbSelect 
            v-model="selectedHours"
            :items="propertyAccessHours"
            item-text="name"
            return-object
            placeholder="Select Access Hours"
            :loading="fetchAccessHoursInProgress"
            :disabled="fetchAccessHoursInProgress || disabled"
            :clearable="false"
        />
    </div>

</template>


<script type="text/babel">

import { notificationMixin } from  '../../mixins/notificationMixin.js';
import api from '../../assets/api.js';

export default{
        name: "AccessHourSelection",
        mixins: [ notificationMixin ],
        data(){
            return {
                fetchAccessHoursInProgress: false,
                propertyAccessHours: [],
                unitsInSelectedArea: [],
            }
        },
        props: {
            useVSelect:{
                type: Boolean,
                default: false
            },
            disabled:{
                type: Boolean,
                default: false
            },
            value:{
                type: Object,
                default: {}
            },
            changeInProgress:{
                type: Boolean,
                default: false
            },
            currentUnitNumber:{
                type: String,
                default: ''
            },
            hideSelection:{
                type: Boolean
            },
            areaId:{
                type: String,
                required: true
            },
            propertyId:{
                type: String,
                required: true
            },
            contactId:{
                type: String,
                required: true
            },
            selectHour:{
                type: String,
                default: ''
            }
        },
        mounted(){
            this.fetchAccessHours();
        },
        computed:{
            selectedHours: {
                get () {
                    return this.value;
                },
                set (value) {
                    this.$emit('input', value);
                }
            }
        },
        methods:{
            fetchAccessHours(){
                if (!this.propertyId) {
                    this.showMessageNotification({ type: 'error', description: 'No Property ID is set or Property ID is invalid.' });
                    return;
                } else {
                    this.fetchAccessHoursInProgress = true;
                    api.get(this, `${api.PROPERTIES}${this.propertyId}/access-levels?active=1&area_id=${this.areaId}`).then(r => {
                        if (r?.length) {

                            this.populatePropertyAccessHours(r);
                        } else {
                            this.$emit("update:hideSelection", true);
                        }
                        this.fetchAccessHoursInProgress = false;
                    }).catch(err => {
                        this.fetchAccessHoursInProgress = false;
                        this.$emit("update:hideSelection", true);
                    });
                }
            },
            populatePropertyAccessHours(accessHoursList){
                let selectHourIndex;
                let defaultHourName;

                //set the vendor access level id property for use in components
                accessHoursList.map((element) => element.vendor_access_level_id = element.id)
                //automatically select the hour from parameter or set to default
                if (this.selectHour) {
                    defaultHourName = this.selectHour;
                } else {
                    defaultHourName = accessHoursList.find((element) => element.isDefault)?.name;
                }

                //find the index if we have a default name so we can select it from the list
                if (defaultHourName) {
                    selectHourIndex = accessHoursList.findIndex((element) => element.name === defaultHourName);
                    //clear selectHour in parent component because new hour is now selected
                    this.$emit("update:selectHour", '');
                }

                if (selectHourIndex != -1) {
                    this.selectedHours = accessHoursList[selectHourIndex];
                } else {
                    this.showMessageNotification({ type: 'error', description: "Default access hour was not found for this area." });
                }
                this.propertyAccessHours = accessHoursList;

            }
        },
        watch:{
            selectedHours() {
                this.$validator.reset('hours');
            },
            areaId() {
                this.fetchAccessHours()
            }
        }
}


</script>
