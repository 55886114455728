<template>
  <div>
    <div class="services-section scrollable-content">

      <div v-show="properties.length > 1" class="mr-n6">

      </div>

      <hb-card title="Guest Information (Optional)">
        <hb-form label="Name" required full>
          <v-row no gutters class="ma-0 pa-0">
            <v-col cols="12" md="6" no-gutters class="ma-0 pa-0">
              <hb-text-field
                v-model.lazy="contact.first"
                v-validate="'required|max:45'"
                placeholder="First"
                name="first"
                data-vv-as="First Name"
                :error="errors.has('first')"
                id="first"
                full
              >
              </hb-text-field>
            </v-col>

            <v-col cols="12" md="6" no-gutters class="ma-0 pa-0" :class="{ 'pl-3' : $vuetify.breakpoint.mdAndUp, 'mt-3' : $vuetify.breakpoint.smAndDown }">
              <hb-text-field
                v-model.lazy="contact.last"
                v-validate="'required|max:45'"
                placeholder="Last"
                name="last"
                data-vv-as="Last Name"
                :error="errors.has('last')"
                id="last"
                full
              >
              </hb-text-field>
            </v-col>
          </v-row>
        </hb-form>

        <hb-form label="Email">
          <hb-text-field
            v-model="contact.email"
            v-validate.disabled="{ max:45, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
            placeholder="Enter Email"
            name="email"
            data-vv-as="Email"
            :error="errors.has('email')"
          >
          </hb-text-field>
        </hb-form>

        <hb-form
          label="Phone"
          full
          last
          class="no-height"
        >
          <div class="d-flex align-top">
          
            <v-col cols="12" class="ma-0 pa-0">
              <v-row no-gutters>
                <v-col cols="12" md="3" class="ma-0 pa-0">
                  <HbSelect
                    v-model="contact.Phones[0].type"
                    :items="phone_types"
                    placeholder="Type"
                    name="type"
                    data-vv-as="Phone Type"
                    :error="errors.has('type')"
                    :clearable="false"
                  />
                </v-col>
                <v-col cols="12" md="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.mdAndUp, 'mt-3' : $vuetify.breakpoint.smAndDown}">
                  <hb-autocomplete
                      v-model="code"
                      :items="countryCodesList"
                      placeholder="Code"
                      :name="code"
                      data-vv-as="Phone Code"
                      :error="errors.has('code')"
                      :clearable="false"
                  >
                  <template v-slot:prepend-inner>
                      +
                  </template>
                  <template v-slot:item="data">
                      +{{data.item}}
                  </template>
                  </hb-autocomplete>
                </v-col>
                <v-col cols="12" md="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.mdAndUp, 'mt-3' : $vuetify.breakpoint.smAndDown}">
                  <HbTextField
                    v-model="number"
                    v-validate="'max:45'"
                    placeholder="Phone Number"
                    name="number"
                    data-vv-as="Phone Number"
                    :error="errors.has('number') || (number.length > 0 && invalidPhones.length > 0)"
                  />
                </v-col>
                <v-col cols="12" md="2" :class="{'pl-2' : $vuetify.breakpoint.mdAndUp, 'mt-3' : $vuetify.breakpoint.smAndDown}">
                  <HbCheckbox
                    v-model="contact.Phones[0].sms"
                    label="SMS"
                  />
                </v-col>
              </v-row>
            </v-col>

          </div>
       </hb-form>
      </hb-card>
    </div>

    <div class="new-tenant-walkthrough-footer">
      <hb-bottom-action-bar
        @close="$emit('close')"
        :top-border="false"
      >
        <template v-slot:left-actions>
          <help-juice />
        </template>
        <template v-slot:right-actions>
          <hb-btn color="primary" :disabled="isLoading($options.name) || isCalculating || disablePayment" :loading="isLoading($options.name) || isCalculating || disablePayment" @click="setContactInfo">Process Payment</hb-btn>
        </template>
      </hb-bottom-action-bar>
    </div>
  </div>

</template>
<script type="text/babel">
    import api from '../../../assets/api.js';
    import Status from './../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import { EventBus } from '../../../EventBus.js';
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import { parsePhoneNumber } from 'libphonenumber-js';
    import moment from 'moment';
	import { notificationMixin } from  '../../../mixins/notificationMixin.js';
	
    export default {
        name: 'LeaseData',
		mixins: [ notificationMixin ],
        components: {
            HbDatePicker,
            Status,
            Loader
        },
        props: {
          checkPaymentsErrors: {
            type: Function
          },
          contactData: {
            type: Object,
            default: {}
          },
          isCalculating: {
            type: Boolean,
            default: false
          },
          disablePayment: {
            type: Boolean,
            default: false
          }
        },
        data: () => ({
            panel: [0],
            currentDate: '',
            // Move to deidicated state picker
            states: [
                'Alabama', 'Alaska', 'American Samoa', 'Arizona',
                'Arkansas', 'California', 'Colorado', 'Connecticut',
                'Delaware', 'District of Columbia', 'Federated States of Micronesia',
                'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho',
                'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
                'Louisiana', 'Maine', 'Marshall Islands', 'Maryland',
                'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
                'Missouri', 'Montana', 'Nebraska', 'Nevada',
                'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
                'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio',
                'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico',
                'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
                'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia',
                'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
            ],
            phone_types: ['Phone','Cell','Home','Work','Fax','Other'],
            code: '1',
            number: '',
            invalidPhones: [],
            found: [],
            countryCodesList: [
                "1",
                "7",
                "20",
                "27",
                "30",
                "31",
                "32",
                "33",
                "34",
                "36",
                "39",
                "40",
                "41",
                "43",
                "44",
                "45",
                "46",
                "47",
                "48",
                "49",
                "51",
                "52",
                "53",
                "54",
                "55",
                "56",
                "57",
                "58",
                "60",
                "61",
                "62",
                "63",
                "64",
                "65",
                "66",
                "81",
                "82",
                "84",
                "86",
                "90",
                "91",
                "92",
                "93",
                "94",
                "95",
                "98",
                "211",
                "212",
                "213",
                "216",
                "218",
                "220",
                "221",
                "222",
                "223",
                "224",
                "225",
                "226",
                "227",
                "228",
                "229",
                "230",
                "231",
                "232",
                "233",
                "234",
                "235",
                "236",
                "237",
                "238",
                "239",
                "240",
                "241",
                "242",
                "243",
                "244",
                "245",
                "246",
                "247",
                "248",
                "249",
                "250",
                "251",
                "252",
                "253",
                "254",
                "255",
                "256",
                "257",
                "258",
                "260",
                "261",
                "262",
                "263",
                "264",
                "265",
                "266",
                "267",
                "268",
                "269",
                "290",
                "291",
                "297",
                "298",
                "299",
                "350",
                "351",
                "352",
                "353",
                "354",
                "355",
                "356",
                "357",
                "358",
                "359",
                "370",
                "371",
                "372",
                "373",
                "374",
                "375",
                "376",
                "377",
                "378",
                "380",
                "381",
                "382",
                "383",
                "385",
                "386",
                "387",
                "389",
                "420",
                "421",
                "423",
                "500",
                "501",
                "502",
                "503",
                "504",
                "505",
                "506",
                "507",
                "508",
                "509",
                "590",
                "591",
                "592",
                "593",
                "594",
                "595",
                "596",
                "597",
                "598",
                "599",
                "670",
                "672",
                "673",
                "674",
                "675",
                "676",
                "677",
                "678",
                "679",
                "680",
                "681",
                "682",
                "683",
                "685",
                "686",
                "687",
                "688",
                "689",
                "690",
                "691",
                "692",
                "850",
                "852",
                "853",
                "855",
                "856",
                "880",
                "886",
                "960",
                "961",
                "962",
                "963",
                "964",
                "965",
                "966",
                "967",
                "968",
                "970",
                "971",
                "972",
                "973",
                "974",
                "975",
                "976",
                "977",
                "992",
                "993",
                "994",
                "995",
                "996",
                "998"
            ],
            contact: {
              id: '',
              first: '',
              last: '',
              email: '',
              company:'',
              rent_as_business:false,
              Phones:[ {
                  type: 'Cell',
                  phone: '',
                  sms: true
              }],
              Addresses: [{
                  id: null,
                  type: 'Home',
                  address_id: '',
                  Address:{
                      address: '',
                      address2: '',
                      city: '',
                      neighborhood: '',
                      lat: '',
                      lng: '',
                      state: '',
                      zip: ''
                  }
              }]
          },
        }),
        async created(){
          this.code = this.company_phone_code;
          if (this.contactData && !this.contactData.id && this.contactData.email !== 'posguestuser@tenantinc.com') {
            this.contact = {
              ...this.contact,
              first: this.contactData.first || '',
              last: this.contactData.last || '',
              email: this.contactData.email || '',
              Phones: this.contactData.Phones && this.contactData.Phones.length 
                ? this.contactData.Phones 
                : [{
                    type: 'Cell',
                    phone: '',
                    sms: true
                }]
            };
          }
        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                activeDrawerComponent: 'navigationStore/activeDrawerComponent',
                payment: 'paymentsStore/getPayment',
                company_phone_code: 'authenticationStore/getCompanyPhoneCode',
            })
        },
        methods:{
            ...mapActions({
              setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent',
            }),
            ...mapMutations({
              setPayment: 'paymentsStore/setPayment'
            }),
            addPhone(){
                this.contact.Phones.push({
                    type: 'Cell',
                    phone: '',
                    sms: true
                })
            },
            removePhone(type, i, j){
                if(type === 'contact' && this.contact.Phones.length > 1){
                    this.contact.Phones.splice(i,1);
                }
            },

            async setContactInfo(contact_id){

              let { first, last, email } = this.contact;
              if ((first && first.trim()) || (last && last.trim()) || (email && email.trim()) || (this.contact.Phones[0].phone && this.contact.Phones[0].phone.trim())) {
                if (this.errors.items.indexOf('The Phone Number is invalid.') >= 0){
                  var invalidPhoneIndex = this.errors.items.indexOf('The Phone Number is invalid.');
                  this.errors.items.splice(invalidPhoneIndex);
                }
  
                let general_status = await this.$validator.validate();
  
                if(this.number.length){
                  let valid_phone_check = this.checkValidPhone(this.code, this.number, 0);
                  if (!valid_phone_check) {
                    this.contact.Phones[0].phone = '';
                    this.errors.items.push('The Phone Number is invalid.');
                  } else {
                    this.contact.Phones[0].phone = this.code + this.number.replace(/\D/g,'');
                  }
                } else {
                  this.contact.Phones[0].phone = '';
                }

                if(!general_status || this.errors.items.indexOf('The Phone Number is invalid.') >= 0) {                
                  this.showMessageNotification({
                    type: 'error', 
                    description: 'There are errors in your form, correct them before continuing.',
                    list: this.errors.items
                  });
                  return;
                }
                let contactName = first + ' ' + last;
                this.setPayment({property : 'ref_name', propertyValue: contactName})
                await EventBus.$emit('HB-Merchandise:contact-info', this.contact);
              }
              this.checkPaymentsErrors()
            },

            phoneMask(code) {
                switch (parseInt(code)) {
                    case 1:
                        return "(###) ###-####";
                    default:
                        return "";
                }
            },

            checkValidPhone(code, number, i){
                if(code || number){
                  try {
                      var parsedPhoneNumber = parsePhoneNumber('+' + code + number);
                  } catch(err){
                    // console.log(err);
                  }
                var r = null;

                if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                  if (this.invalidPhones.indexOf(0) != -1){
                    this.invalidPhones.splice(this.invalidPhones.indexOf(0), 1);
                  }
                  r = true;
                } else if((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number.length > 0) {
                  if (this.invalidPhones.indexOf(0) != -1){
                    '';
                  } else {
                    this.invalidPhones.push(0);
                  }
                  r = false;
                } else {
                  if (this.invalidPhones.indexOf(0) != -1){
                    this.invalidPhones.splice(this.invalidPhones.indexOf(0), 1);
                  }
                  r = false;
                }
                return r;
              }
            },

            resetForm(){
                this.contact = {
                    first: '',
                    last: '',
                    email: '',

                    Phones:[ {
                        type: 'Cell',
                        phone: '',
                        sms: true
                    }],
                    Addresses: [
                        {
                            id: null,
                            type: 'Home',
                            address_id: '',
                            Address:{
                                address: '',
                                address2: '',
                                city: '',
                                neighborhood: '',
                                lat: '',
                                lng: '',
                                state: '',
                                zip: ''
                            }
                        }
                    ]
                }
            }

        },
        watch:{
          'contact.id': {
            handler: function(params) {
              if(this.contact.id && this.contact.Phones[0].phone.length){
                  try {
                    var parsedPhoneNumber = parsePhoneNumber('+' + this.contact.Phones[0].phone);
                    if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                      this.code = parsedPhoneNumber.countryCallingCode;
                      this.number = parsedPhoneNumber.nationalNumber;
                    }
                  } catch(err){
                    console.log(err);
                  }
              } else {
              this.code = '';
              this.number = '';
            }
        }
      },
      'contact.last': {
        handler: function(params) {
          if(this.contact.first.trim() || this.contact.last.trim()) {
            this.setPayment({property : 'ref_name', propertyValue: this.contact.first + ' ' + this.contact.last})
          }
        }

      }
    }
  }

</script>

<style scoped>
  hr.v-divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .lease-section{
    padding: 20px 50px 100px 50px;
  }


  .v-chip {
    padding: 0 12px 0 5px;
  }
  .v-chip i.v-icon{
    margin-right: 5px;
  }

  .v-expansion-panel{
    border: 1px solid #E1E6EA;
  }
  .v-expansion-panel:before { box-shadow: none !important; }

  .v-expansion-panel-header.v-expansion-panel-header--active{
    border-bottom: 1px solid #E1E6EA;

  }

  .v-expansion-panel.new-tenant-form-data {
    margin-bottom: 20px;
  }

  .input-action{
    padding: 5px;
    cursor: default;
  }

  .services-section{
    padding-bottom: 24px;
  }

  .new-tenant-walkthrough-footer{
    padding: 3px 0 3px 0;
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
  }

</style>

<style>
.no-height > .row.no-gutters {
  min-height: auto !important;
}
</style>

