<template>
  <div v-if="exemptflags.length">
    <div 
      @click="showPermissionNotification(exempt_flag)" 
      :style="i < exemptflags.length - 1 ? 'border-bottom: 1px solid #e0e0e0;' : ''" 
      v-for="(exempt_flag, i) in exemptflags" 
      :key="'exempt_flag' + i"
    >
      <hb-form class="exempt-delinquency-items-form-input-wrapper" @cancel="cancel" :divider="false" :label="exempt_flag.name"
        :active.sync="active" :editable="!is_closed && hasPermission(`${exempt_flag.slug}`)" @save="openConfirmationModal(exempt_flag, i)">
        <!-- <template v-slot:tooltipBody>
          <div style="width: 250px">
            {{ exempt_flag.description | capitalize }}
          </div>
        </template> -->
        <template v-slot:display>
          <div class="mt-0 pt-0" :class="{ 'ml-4': is_closed }">
            <span class="hb-text-light">{{ getFlagInfo(exempt_flag) | capitalize }}</span>
            <HbCheckbox 
              class="mt-1 exempt_checkbox" 
              v-model="exempt_flag.status" 
              :label="getCheckboxLabel(exempt_flag) | capitalize" readonly 
            />
            <div v-if="exempt_flag?.status" class="hb-text-light mt-1 mb-1">{{ exempt_flag.reason }}</div>
            <div v-if="exempt_flag?.modified_at && exempt_flag?.contact_name">
              <div class="text-body-2 hb-text-light">
                Last Modified By: {{exempt_flag?.contact_name }} @ {{ convertToPropertyDateTime(exempt_flag?.modified_at) | formatDateCustom('MMM DD, YYYY hh:mm A') }}
              </div>
            </div>
          </div>
        </template>
        <template v-slot:edit>
          <span class="hb-text-light">{{ getFlagInfo(exempt_flag) | capitalize}}</span>
          <HbCheckbox 
            class="mt-1 exempt_checkbox" 
            v-model="exempt_flag.status" 
            :label="getCheckboxLabel(exempt_flag) | capitalize" 
          />
          <HbTextarea 
            v-if="exempt_flag.status" 
            :rows=6 
            row-height="15" 
            v-model="exempt_flag.reason"
            v-validate="'required|max:255'" 
            :maxlength="255" data-vv-scope="flags" 
            placeholder="Reason for status change"
            placeholder-fix 
          />
            <div v-if="exempt_flag?.modified_at && exempt_flag?.contact_name">
              <div class="text-body-2 hb-text-light">
                Last Modified By: {{
                  exempt_flag?.contact_name }} @ {{ convertToPropertyDateTime(exempt_flag?.modified_at) | formatDateCustom('MMM DD, YYYY hh:mm A') }}
              </div>
            </div>
        </template>
      </hb-form>
      <hb-modal v-if="showConfirmation[i]" v-model="showConfirmation[i]" size="medium" :title='getConfirmationModelTitle(exempt_flag) | capitalize' @close="cancel" confirmation>
        <template v-slot:content>
          <div class="py-4 px-6">
            Are you sure you want to update the selected lease {{ exempt_flag.name }}?
            <br /><br />
          </div>
        </template>
        <template v-slot:actions>
          <hb-btn :loading="saveFlagInProgress" :disabled="saveFlagInProgress" @click="saveLeaseExemptItems(exempt_flag, i)">{{ getConfirmationModelTitle(exempt_flag) | capitalize }}</hb-btn>
        </template>
      </hb-modal>
    </div>
  </div>
</template>

<script type="text/babel">
import api from "../../assets/api.js";
import { EventBus } from "../../EventBus.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { mapGetters, mapMutations } from "vuex";
import { isEqual } from "lodash";
import FLAG_CHANGES_OBJECTS from '@/constants/flag_changes_objects.js'
import propertyCurrentDateMixin from '@/mixins/propertyCurrentDateMixin.js'

export default {
  name: "ExemptItems",
  mixins: [notificationMixin, propertyCurrentDateMixin],
  data() {
    return {
      active: "",
      oldExemptflags: [], 
      exemptflags: [], 
      showConfirmation: [],
      statusChanged: false,
      saveFlagInProgress: false
    };
  },
  props: {
    lease: {
      type: Object,
      required: true,
    },
    is_closed: {
      type: Boolean,
      default: false
    }
  },
  created(){
    EventBus.$on("reload_tenant_profile_leases", this.fetchLeaseExemptFlagsDetails);
  },
  async mounted() {
    try { 
      await this.fetchLeaseExemptFlagsDetails(); 
    } catch (error) {
      this.showMessageNotification({ description: error });
    }
  },
  destroyed(){
    EventBus.$off("reload_tenant_profile_leases", this.fetchLeaseExemptFlagsDetails);
  },
  methods: {
    ...mapMutations({
        setRefreshTenantProfile: 'tenantProfile/setRefreshTenantProfile',
    }),
    showPermissionNotification(exempt_flag){
      if(!this.hasPermission(`${exempt_flag.slug}`)){
        this.showMessageNotification({ description: `You don\'t have enough permissions to update ${exempt_flag.name}.` });
        return;
      }
    },
    getConfirmationModelTitle(exempt_flag){
        return `Update ${exempt_flag?.name ? exempt_flag.name : 'flag exempt'}`;
    },
    getCheckboxLabel(exempt_flag){
        return `Mark tenant as ${exempt_flag?.name ? exempt_flag.name : 'flag exempt'}`;
    },
    getFlagInfo(exempt_flag){
      if(exempt_flag?.slug === FLAG_CHANGES_OBJECTS.LEASE_EXEMPT_FLAGS.EXEMPT_DELINQUENCY){
        return 'Checking this box means the tenant will be exempt from delinquency and will not trigger automated delinquency actions moving forward.';
      }else{
        return `Checking this box means that the tenant will be ${exempt_flag?.name ? exempt_flag.name : 'Flag Exempt'} moving forward.`;
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    async fetchLeaseExemptFlagsDetails(){
      try {
        let r = await api.get(
          this,
          api.FLAG_CHANGES_OBJECTS + "flag-types-details/" + "lease/" + this.lease.id
        );
        this.exemptflags = r.flags;
        this.oldExemptflags = JSON.parse(JSON.stringify(r.flags));
      }
      catch (error) {
        this.showMessageNotification({ description: error });
      }
    },
    async openConfirmationModal(exempt_flag, i){
      if (!exempt_flag?.reason) {
        this.showMessageNotification({ description: 'Please enter reason for status change' });
        return;
      }
      this.$set(this.showConfirmation, i, true);
    },
    async saveLeaseExemptItems(updatedFlag, i) {
      this.saveFlagInProgress = true;
      let prevFlag = this.oldExemptflags.length && this.oldExemptflags.find(exemptflag => exemptflag.id == updatedFlag?.id);
      let status = updatedFlag?.status ? 1 : 0;
      this.statusChanged = (prevFlag?.status !== status) ? true : false;
      if(!isEqual(prevFlag, updatedFlag)){
         try {
            let data = {
              flag_value: updatedFlag.status,
              description: updatedFlag.reason,
              is_flag_value_changed: this.statusChanged ? true : false
            };
            let r = await api.post(
              this,
              api.FLAG_CHANGES_OBJECTS + this.lease.id + "/flag-type/" + "lease/" + updatedFlag.flag_type_id, 
              data
            );
            if (r.flag) {
              this.showMessageNotification({ type: 'success', description: `You have successfully updated the Lease ${updatedFlag.name} Status.` });
              setTimeout(function() {
                EventBus.$emit('reload_tenant_profile_leases');
                EventBus.$emit('reload_tenant_profile_delinquencies');
              }, 3000); 
              this.active = '';
            }
          }
          catch (error) {
            this.showMessageNotification({ description: error });
          }
      }
      this.$set(this.showConfirmation, i, false); 
      this.saveFlagInProgress = false
    },
    convertToPropertyDateTime(date){
      let leasePropertyDate = this.getPropertyDateTime(this.lease?.Unit?.property_id,this.properties, date);
      return leasePropertyDate ? leasePropertyDate : date;
    },
  },
  computed:{
    ...mapGetters({
      hasPermission: "authenticationStore/rolePermission",
      properties: 'propertiesStore/filtered',
    }),
  },
  watch: {
    lease() {
      this.fetchLeaseExemptFlagsDetails();
    },
  }
};

</script>

<style scoped>
  .protected-item .v-label {
    margin-top: 0px !important;
  }

  .exempt-delinquency-items-form-input-wrapper .v-input--selection-controls:not(.v-input--is-label-active) .v-input--selection-controls__input>i.v-icon,
  .hb-aviary-v-checkbox.v-input--selection-controls.v-input--is-disabled:not(.v-input--is-label-active) .v-input--selection-controls__input>i.v-icon {
    color: #DFE3E8 !important;
  }
  .exempt_checkbox label > div > span{
    margin-top: 3px !important;
  }
</style>
